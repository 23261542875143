import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="bp">
            <div className="wrapper bp">
              <header>
                <h1 className="logo">
                  <a href="/">Neutrogena</a>
                </h1>
                <nav>
                  <a href="/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/neutrogena/o-nas/" className="link">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>| </span>
                  <a href="/budte-krasna/" className="section">
                    Buďte krásná
                   </a>
                   
                  <span />
                   
                  <span />
                </nav>
              </header>
              <div className="top">
                <h2>Péče o tělo</h2>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/budte-krasna/">Buďte krásná</a> <span>&gt;</span>
                  Péče o tělo
                </div>
              </div>
            </div>
          </div>
          <div className="submenu bpmenu">
            <div className="wrapper">
              <a href="/budte-krasna/sucha-pokozka/" className="link">
                Suchá pokožka
              </a>
              <a href="/budte-krasna/pece-o-plet/" className="link">
                Péče o pleť
              </a>
              <a href="/budte-krasna/pece-o-rty/" className="link">
                Péče o rty
              </a>
              <a href="/budte-krasna/pece-o-ruce/" className="link">
                Péče o ruce
              </a>
              <a href="/budte-krasna/pece-o-nohy/" className="link">
                Péče o nohy
              </a>
              <a href="/budte-krasna/pece-o-tlo/" className="current">
                Péče o tělo
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="content">
              <a name="aboutAnchor" />
              <section className="first">
                <div
                  className="text full"
                  style={{
                    "padding-bottom": "25px"
                  }}
                >
                  <h2>
                    Zdravá a krásná pokožka&nbsp;
                    <br />
                    vyžaduje&nbsp;náležitou každodenní péči
                  </h2>
                  <p>
                    Abyste se účinně starala o její zdraví, měla byste si
                    zapamatovat několik základních pravidel,
                  </p>
                  <p>
                    které se stanou účinným rituálem pro péči o pokožku: mytí
                    těla, tělový peeling, chytrá hydratace a intenzivní
                    regenerace.
                  </p>
                </div>
                <div className="text half left">
                  <h2>Mytí těla&nbsp;</h2>
                  <p>
                    Mytí těla je prvním a tudíž velmi důležitým krokem v
                    každodenní péči. K mytí pokožky zvolte nedráždivé mýdlo či
                    sprchový gel, které se dobře smývají. Tak předejdete tomu,
                    aby byla pokožka vystavena riziku&nbsp;narušení
                    hydrolipidového filmu&nbsp;a vysušení. Rovněž dbejte na to,
                    abyste pokožku nedrhla po sprchování hrubým ručníkem. Jemně
                    ji ručníkem osušte a připravte tělo na hydrataci.
                  </p>
                </div>
                <div className="text half right">
                  <h2>Tělový peeling</h2>
                  <p>
                    Používání tělového peelingu je velmi důležitým krokem při
                    účinné péči o pokožku. Umožní vám pokožku plně připravit na
                    aplikaci hydratačních krémů a umocní jejich účinek. Peeling
                    zbavuje pokožku mrtvých kožních buněk a čistí ji do větší
                    hloubky než jiné mycí prostředky (gely, mýdla), a pomáhá jí
                    tak udržovat mladistvý vzhled po delší dobu. Peeling by však
                    neměl být používán častěji než dvakrát týdně. Vaše pokožka
                    potřebuje čas na efektivní regeneraci drobných poškození
                    způsobených peelingem. Následně hydratujte pokožku s
                    použitím vhodné kosmetiky.
                  </p>
                </div>
              </section>
              <section>
                <div className="bg cialo1">
                  <div className="text left">
                    <p>
                      <br />
                      <br />
                      <br />
                    </p>
                    <h2>Intenzivní regenerace</h2>
                    <p>
                      Není nic nepříjemnějšího než suchá a napnutá pokožka.
                      Abyste jí navrátila jas a energii, použijte výrobky řady
                      Intezivní regenerace s obsahem Norské receptury.
                      Regenerace pokožky je nezbytná nejen v zimě, kdy je
                      vystavena suchému, chladnému a větrnému počasí, ale i v
                      létě, kdy je&nbsp;namáhána sluněním. Intenzivní
                      regenerační přípravky hydratují a pomáhají regenerovat
                      a&nbsp;přinášejí úlevu suché, svědivé a napnuté pokožce.
                    </p>
                  </div>
                </div>
              </section>
              <section>
                <div className="bg cialo2">
                  <div className="text right">
                    <p>
                      <br />
                      <br />
                      <br />
                    </p>
                    <h2>Chytrá hydratace</h2>
                    <p>
                      Každodenní hydratace pokožky je jako dýchání. Používání
                      vhodné kosmetiky pomáhá účinně obnovovat její
                      rovnováhu.&nbsp;Produkty NEUTROGENA<sup>®</sup>
                      &nbsp;zjemňují pokožku a vytvářejí vrstvy bránící úbytku
                      vody z pokožky.&nbsp;Hloubkově hydratační řada výrobků
                      NEUTROGENA<sup>®</sup>&nbsp;byla vytvořena pro péči o
                      suchou pokožku. Dle svého určení nabízí až 24hodinovou
                      hydrataci, u tělových mlék a balzámů proniká až do 10-ti
                      vrstev pokožky*.&nbsp;&nbsp;Napomůže Vám získat pokožku
                      zdravou, krásnou a hedvábně hebkou.&nbsp;
                    </p>
                    <p>&nbsp;</p>
                    <p>*horní vrstvy epidermis</p>
                  </div>
                </div>
              </section>
              <section className="bg cialo3">
                <div className="text full">
                  <h2>Produkty a období</h2>
                </div>
                <div className="text half left">
                  <div className="p">
                    <p>
                      Aby Vaše pokožka vždy zářila přirozenou krásou, dbejte na
                      to, abyste uzpůsobila svou kosmetiku měnícím se
                      povětrnostním podmínkám.
                    </p>
                    <p>&nbsp;</p>
                    <ul>
                      <li>
                        V zimě pokožku řádně vyživujte a hydratujte. Pokud jste
                        vystavena chladnému a větrnému počasí, pravidelně
                        používejte výrobky řady Norská receptura Intenzivní
                        regenerace.
                      </li>
                      <li>
                        &nbsp;V létě nezapomínejte chránit svou pokožku před UVA
                        slunečním zářením a pravidelně ji hydratujte. Zvažte
                        používání řady NEUTROGENA<sup>® </sup>Norská receptura
                        Hloubková hydratace či vyživující řadu Nordic Berry.
                        Dbejte na to, abyste nejteplejší části dne trávila
                        uvnitř.&nbsp;
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="text half right">
                  <div className="p">
                    <p>
                      Výrobky tělové péče s obsahem Norské receptury zahrnují:
                    </p>
                    <p>&nbsp;</p>
                    <ul>
                      <li>
                        Vyživující řadu s ostružiníkem moruška Nordic berry
                      </li>
                      <li>Hloubkově hydratační řadu</li>
                      <li>Intenzivně regenerační řadu</li>
                    </ul>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </section>
              <div id="fb_70"></div>
            </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/neutrogena/o-nas/">
                  O ZNAČCE NEUTROGENA<sup>®</sup>
                </a>
                <span>|</span>
                <a href="/neutrogena/norska-receptura/">Norská receptura</a>
                <span>|</span>
                <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
                <span>|</span>
                <a href="/produkty/">
                  Zobrazit produkty NEUTROGENA<sup>®</sup>
                </a>
              </div>
            </div>
          </div>
          <Footer></Footer>
          <script
            dangerouslySetInnerHTML={{
              __html:
                "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
            }}
          />
        </div>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
